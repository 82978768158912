<template>
  <div>
    <v-btn-toggle
          v-model="activeView"
          color="primary"
          class="mt-3 mt-sm-0"
          @change="changeViewOption"
        >
          <v-btn
            v-for="viewOption in viewOptions"
            :key="viewOption"
            elevation="0"
            style="text-transform: none"
            :value="viewOption"
          >
            {{ viewOption }}
          </v-btn>
    </v-btn-toggle>
    <span class="text-2xl font-weight-semibold me-1">
        &nbsp;&nbsp;&nbsp;{{realTime}}
    </span><br><br>
    <v-row>
      <v-col
        v-if="dashboardDisplayItems.includes(1)"
        cols="12"
        md="4"
        sm="6"
        order="1"
        class="align-self-end"
      >
        <ent-statistics-card-line-chart
        :user-data="userData"
        ></ent-statistics-card-line-chart>
      </v-col>

      <v-col
        v-if="dashboardDisplayItems.includes(2)"
        cols="12"
        md="4"
        sm="6"
        order="2"
        class="align-self-end"
      >
        <people-car-bike-statistics-card-line-chart
        :user-data="userData"
        ></people-car-bike-statistics-card-line-chart>
      </v-col>
      <v-col
        v-if="dashboardDisplayItems.includes(3)"
        cols="12"
        md="4"
        sm="6"
        order="5"
        order-md="3"
      >
        <stay-statistics-card-line-chart
        :user-data="userData">
        </stay-statistics-card-line-chart>
      </v-col>

      <v-col
        v-if="dashboardDisplayItems.includes(4)"
        cols="12"
        md="4"
        sm="6"
        order="3"
        order-md="4"
      >
        <sc-comparison-statistics-card-line-chart
        :user-data="userData">
        </sc-comparison-statistics-card-line-chart>
      </v-col>

      <v-col
        v-if="dashboardDisplayItems.includes(5)"
        cols="12"
        md="8"
        sm="12"
        order="6"
      >
        <weather-forecast-card
        v-if="activeView === 'Live'"
        ></weather-forecast-card>
        <weather-info-card
        v-if="activeView !== 'Live'"
        :weatherInfoData="userData.weatherInfoData"
        ></weather-info-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import store from '@/store'
import WeatherForecastCard from './WeatherForecastCard.vue'
import WeatherInfoCard from './WeatherInfoCard.vue'
import dashboardsStoreModule from './DashboardsStoreModule'
import { getSessionData, dateFormattedddmmmyyydd, isEmpty } from '@core/utils'
import EntStatisticsCardLineChart from './EntStatisticsCardLineChart.vue'
import PeopleCarBikeStatisticsCardLineChart from './PeopleCarBikeStatisticsCardLineChart.vue'
import StayStatisticsCardLineChart from './StayStatisticsCardLineChart.vue'
import ScComparisonStatisticsCardLineChart from './ScComparisonStatisticsCardLineChart.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { constant } from '@core/utils/constant'
import crypto from 'crypto-js'

const USER_APP_STORE_MODULE_NAME = 'app-dashboards'

export default {
  components: {
    WeatherInfoCard,
    WeatherForecastCard,
    EntStatisticsCardLineChart,
    PeopleCarBikeStatisticsCardLineChart,
    StayStatisticsCardLineChart,
    ScComparisonStatisticsCardLineChart,
  },
  mounted() {
    this.$dateRangePickerDisplayFlag = false
    this.$datePickerDisplayFlag = false
    this.$selectScDisplayFlag = true
    this.$selectDomesticScDisplayFlag = false
    this.$domesticDatePickerDisplayFlag = false

    this.showRealTime()
  },
  methods: {
    refreshTime() {
      const date = new Date()
      if (this.activeView === 'Live') {
        const minutes = date.getMinutes().toString()
        let varMinutes
        if (minutes.length < 2) {
          varMinutes = '00'
        } else {
          varMinutes = `${minutes.substr(0, 1)}0`
        }
        this.realTime = `${dateFormattedddmmmyyydd(date)} ${date.getHours().toString().padStart(2, '0')} : ${varMinutes} `
      } else {
        date.setDate(date.getDate() - 1)
        this.realTime = `${dateFormattedddmmmyyydd(date)}`
      }
    },
    showRealTime() {
      setInterval(this.refreshTime, 1000)
    },
  },
  setup() {
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, dashboardsStoreModule)
    }
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const userData = ref()
    const currentDate = new Date()

    const siteId = String(getSessionData('data').siteId)

    const ecryptedData = sessionStorage.getItem('siteList')

    // 復号する
    const decrypted = crypto.AES.decrypt(ecryptedData, constant.secret)
    const siteList = JSON.parse(decrypted.toString(crypto.enc.Utf8))

    let siteIds
    siteList.forEach(element => {
      if (isEmpty(siteIds)) {
        siteIds = `${element.id}`
      } else {
        siteIds = `${siteIds},${element.id}`
      }
    })

    const activeView = ref('Live')
    const viewOptions = ['Live', 'Yesterday']

    const changeViewOption = () => {
      let searchDate
      if (activeView.value === 'Live') {
        searchDate = `${currentDate.getFullYear()}
          -${(currentDate.getMonth() + 1).toString().padStart(2, '0')}
          -${currentDate.getDate().toString().padStart(2, '0')}`.replace(/\s+/g, '')
      } else {
        searchDate = `${currentDate.getFullYear()}
          -${(currentDate.getMonth() + 1).toString().padStart(2, '0')}
          -${(currentDate.getDate() - 1).toString().padStart(2, '0')}`.replace(/\s+/g, '')
      }
      store
        .dispatch('app-dashboards/fetchData', {
          params: {
            siteIds,
            siteId,
            searchDate,
          },
        })
        .then(response => {
          userData.value = response.data.result.data
        })
        .catch(error => {
          // ログイン画面に遷移する
          console.log(error)

          this.$router.push({ name: 'error-login' })
        })
    }

    changeViewOption()

    const realTime = ref()

    const dashboardDisplayItems = JSON.parse(localStorage.getItem('dashboardDisplayItems'))

    return {
      changeViewOption,
      activeView,
      viewOptions,
      dashboardDisplayItems,
      isEmpty,
      realTime,
      userData,
      currentDate,
      dateFormattedddmmmyyydd,
    }
  },
}
</script>
