<template>
  <!-- <div class="forcast-list">
    <h2 class="forcast-list__title">{{ forecast.dateLabel }}</h2>
    <dl>
      <dt class="forcast-list__telop">{{ forecast.telop }}</dt>
      <dt>
        <img
          v-show="forecast.image.url"
          :src="forecast.image.url"
          class="forcast-list__image"
          alt="forcast.image.title">
      </dt>
    </dl>
  </div> -->

<v-card outlined>
  <div class="daysDetail">
 <h2>{{ weatherInfo.timeStamp }}</h2>
      <dt>
        <img
            :src="require(`@/assets/weater_elements/${weatherInfo.main}.svg`)"
            width="50px"
            alt
          />
      </dt>

      {{ weatherInfo.min }}°-{{ weatherInfo.max }}
    </div>
</v-card>

  <!-- <div class="d-flex justify-content-center">
  </div> -->

</template>

<script>
export default {
  props: {
    weatherInfo: {
      type: Object,
      required: true,
    },
  },
}
</script>
<style scoped>
.daysDetail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-size: 13px;
  padding: 0 10px;
  margin: 0 20px;
}
</style>
