<template>
  <v-card class="d-flex flex-column">
    <v-card-title class="font-weight-semibold">
      <div>
        <p class="text-lg font-weight-semibold mb-2 ">
          Weather Information
        </p>
      </div>
    </v-card-title>
      <v-col
        v-for="(weatherInfoList, i) in weatherInfoData"
        :key="i"
      >
      <v-row
      class="card"
    >
         <info-card
         class="pa-2"
         outlined
        v-for="(weatherInfo, j) in weatherInfoList"
        :weatherInfo="weatherInfo"
        :key="`weather__info-key-${i}${j}`"/>
           </v-row>
      </v-col>

  </v-card>
</template>

<script>
import infoCard from '../components/weather/infoCard.vue'

export default {
  components: { infoCard },

  props: {
    weatherInfoData: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
<style scoped>
.card {
  width: 100%;
  background-color: white;
  border-radius: 10px;
  /* margin-top: 20px; */
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: auto;
}
</style>
