<template>
  <v-card class="d-flex flex-column">
    <v-card-title class="font-weight-semibold">
      <div>
        <p class="text-lg font-weight-semibold mb-2 ">
          Staying Visitor
        </p>
        <span v-if="!isEmpty(userData)" class="text-sm font-weight-semibold me-1">Total :{{ String(userData.stayTotal).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') }}</span><br>
        <span class="text-sm font-weight-semibold me-1"></span><br>
        <span class="text-sm font-weight-semibold me-1"></span>
      </div>
    </v-card-title>
      <div
        ref="lineChart"
        :style="{ width: '100%', height: '300px'}"
      ></div>
  </v-card>
</template>

<script>
import { isEmpty } from '@core/utils'
import 'echarts/lib/chart/line'

const echarts = require('echarts')

export default {
  props: {
    userData: {
      type: Object,
      requires: true,
    },
  },
  watch: {
    // userData を watch する
    userData(val) {
      setTimeout(() => {
        this.drawLine(val)
      }, 500)
    },
  },
  methods: {
    drawLine(val) {
      const lineChartOption = {
        tooltip: { trigger: 'axis' },
        grid: {
          left: '3%',
          right: '3%',
          top: '3%',
          containLabel: true,
        },
        xAxis: [
          {
            axisTick: {
              alignWithLabel: true,
            },
            boundaryGap: [0, 0.01],
            axisLabel: {
              interval: 0,
              rotate: 40,
            },
            data: val.legendData,
          },
        ],
        yAxis: [
          {
            splitNumber: 10,
          },
        ],
        series: [
          {
            type: 'line',
            label: {
              normal: {
                show: false,
              },
            },
            data: val.staySeriesData[0].data,
          },
        ],
      }
      let lineChart = echarts.getInstanceByDom(this.$refs.lineChart)
      if (lineChart == null) {
        lineChart = echarts.init(this.$refs.lineChart)
      }

      lineChart.setOption(lineChartOption, true)
    },
  },
  setup() {
    return {
      isEmpty,
    }
  },
}
</script>
