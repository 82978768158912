<template>
  <v-card class="d-flex flex-column">
    <v-card-title class="font-weight-semibold">
      <div>
        <p class="text-lg font-weight-semibold mb-2 ">
          People, Car, Bike
        </p>
        <span v-if="!isEmpty(userData)" class="text-sm font-weight-semibold me-1">People :{{ String(userData.todayTotal).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') }}</span><br>
        <span v-if="!isEmpty(userData)" class="text-sm font-weight-semibold me-1">Car :{{ String(userData.carTotal).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') }}</span><br>
        <span v-if="!isEmpty(userData)" class="text-sm font-weight-semibold me-1">Bike :{{ String(userData.bikeTotal).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') }}</span>
      </div>
    </v-card-title>
      <div
        ref="lineChart"
        :style="{ width: '100%', height: '300px'}"
      ></div>
  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import { isEmpty } from '@core/utils'
import 'echarts/lib/chart/line'

const echarts = require('echarts')

export default {
  props: {
    userData: {
      type: Object,
      requires: true,
    },
  },
  watch: {
    // userData を watch する
    userData(val) {
      setTimeout(() => {
        this.drawLine(val)
      }, 500)
    },
  },
  methods: {
    drawLine(val) {
      const newSeriesData = []

      for (let i = 0; i < Object.values(val.peopleCarBikeSeriesData).length; i += 1) {
        const element = Object.values(val.peopleCarBikeSeriesData)[i]
        const dataArray = ref([])
        element.facilityInfoData.forEach(item => {
          dataArray.value.push(item.facilityValue)
        })
        const newData = {
          name: element.name,
          type: 'line',
          label: {
            normal: {
              show: false,
            },
          },
          data: dataArray.value,
        }
        newSeriesData.push(newData)
      }

      const lineChartOption = {
        tooltip: { trigger: 'axis' },
        legend: {
          bottom: '3%',
          left: 'center',
        },
        grid: {
          left: '3%',
          right: '3%',
          top: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: [0, 0.01],
          axisLabel: {
            interval: 0,
            rotate: 40,
          },
          data: val.legendData,
        },
        yAxis: {},
        series: newSeriesData,
      }
      let lineChart = echarts.getInstanceByDom(this.$refs.lineChart)
      if (lineChart == null) {
        lineChart = echarts.init(this.$refs.lineChart)
      }

      lineChart.setOption(lineChartOption, true)
    },
  },
  setup() {
    return {
      isEmpty,
    }
  },
}
</script>
